export const showDetailPane = () => {
	return {
		type: "SHOW_DETAIL_PANE"
	}
}

export const hideDetailPane = () => {
	return {
		type: "HIDE_DETAIL_PANE"
	}
}

export const toggleSidenav = (bool) => {
	return {type: "TOGGLE_SIDENAV", bool}
}